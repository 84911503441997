import React, { Component, Context } from 'react';

import { selectDetailsFeatures } from '../../../Details/selectors/detailsSelectors';
import { Features } from '../../../Member/Folders/types';
import { selectMemberBinFeatures } from '../../../Member/MemberBins/selectors/foldersSelectors';
import {
  AUDIOBLOCKS,
  VIDEOBLOCKS,
} from '../../../common/SiteConstants/SiteConstants';
import {
  StockItem,
  StockItemContext,
} from '../../../common/types/StockItemTypes';
import { get, getCurrentSite } from '../../../common/utils';
import { SearchPaginationState } from '../SearchTypes';
import SearchResults from '../components/searchResults/SearchResults';
import {
  selectHideSearchResults,
  selectSearchIsLoading,
  selectSearchIsLoadingAdditionalResults,
  selectPagination,
  selectSearchFeatures,
  selectSearchFilterOptions,
  selectStockItems,
  selectSelectedStockItem,
  selectSearchLogParameters,
  selectUsername,
  selectSearchContext,
  selectDrawerIsOpen,
  selectDrawerSelectedItemId,
  selectSearchStatusCode,
  selectSearchCountryCode,
} from '../selectors/searchSelectors';
import { AnySearchFilterOptions } from './MenuContainerInterfaces';

interface Props {
  selectedStockItemId?: number;
  selectedStockItemIndex?: number;
  selectedSearchFilterOptions: AnySearchFilterOptions;
  features: Features;
  stockItems: StockItem | StockItem[];
  isLoading?: boolean;
  searchLogParameters: object;
  shouldShowAddToFavorites?: boolean;
  favoritesBinId?: number;
  drawerIsOpen: boolean;
  drawerSelectedItemId: number;
  statusCode?: number;
  countryCode?: string;
  hideSearchResults?: boolean;
  isLoadingAdditionalResults?: boolean;
  pagination: Partial<SearchPaginationState>;
  useSubscriptionlessSignUpEnticement: boolean;
  context?: Context<any>;
  shouldUseRemainingSearchUi?: boolean;
}

class AbstractSearchResultsContainer extends Component<Props> {
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const stockItemContainerResponsiveClassNamesForVideoMobileFilters = [
      'relative',
      'float-left',
      'md:w-1/2',
      'lg:w-1/3',
      '3xl:w-1/4',
      '4xl:w-1/5',
      'w-full',
    ];

    return (
      <SearchResults
        currentStockItems={this.getCurrentStockItems()}
        searchLogParameters={this.props.searchLogParameters}
        isLoading={this.props.isLoading}
        isLoadingAdditionalResults={this.props.isLoadingAdditionalResults}
        selectedStockItemDetails={
          this.props.stockItems[this.props.selectedStockItemId]
        }
        selectedStockItemIndex={this.props.selectedStockItemIndex}
        itemsPerRow={this.getItemsPerRow()}
        goToSearchResultsPage={this.goToPage}
        selectedSearchFilterOptions={this.props.selectedSearchFilterOptions}
        redirectToSignUpLink={this.props.features.redirectToSignUpLink}
        isEnterpriseMember={this.props.features.isEnterpriseMember}
        pagination={this.getPagination()}
        shouldShowAddToFavorites={this.props.shouldShowAddToFavorites}
        favoritesBinId={this.props.favoritesBinId}
        context={this.props.context || StockItemContext.SEARCH}
        drawerIsOpen={this.props.drawerIsOpen}
        drawerSelectedItemId={this.props.drawerSelectedItemId}
        drawerNumberItemsVisible={0}
        statusCode={this.props.statusCode}
        countryCode={this.props.countryCode}
        useSubscriptionlessSignUpEnticement={
          this.props.useSubscriptionlessSignUpEnticement
        }
        hideSearchResults={this.props.hideSearchResults}
        stockItemContainerResponsiveClassNames={
          getCurrentSite() === VIDEOBLOCKS
            ? stockItemContainerResponsiveClassNamesForVideoMobileFilters
            : undefined
        }
        searchResultsContainerCSSClasses={
          getCurrentSite() === VIDEOBLOCKS &&
          this.props.shouldUseRemainingSearchUi
            ? 'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'
            : ''
        }
      />
    );
  }

  getItemsPerRow(customWindowWidth?: number) {
    // need for alt versions drawer on audio to be positioned correctly. there is only ever 1 item per for audio.
    if (getCurrentSite() === AUDIOBLOCKS) {
      return 1;
    }

    const windowWidth = customWindowWidth || window.innerWidth;
    if (windowWidth < 672) {
      return 1;
    } else if (windowWidth < 1024) {
      return 2;
    } else if (windowWidth < 1800) {
      return 3;
    } else if (windowWidth < 2300) {
      return 4;
    } else {
      return 5;
    }
  }

  getCurrentStockItems() {
    return this.props.stockItems;
  }

  getPagination() {
    return this.props.pagination;
  }

  /**
   * @type {(newPageNo: number) => void}
   */
  goToPage = () => {
    throw new TypeError(this.constructor.name + ' must implement goToPage()');
  };
}

export function mapStateToProps(state, ownProps) {
  const contextFromRedux = selectSearchContext(state);
  const context = (ownProps && ownProps.context) || contextFromRedux;
  const features = selectSearchFeatures(state);
  const selectedStockItem = selectSelectedStockItem(state);
  const detailsFeatures = selectDetailsFeatures(state);
  const memberBinFeatures = selectMemberBinFeatures(state);

  return {
    selectedStockItemId: selectedStockItem.id,
    selectedStockItemIndex: selectedStockItem.index,
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    features: selectSearchFeatures(state),
    stockItems: selectStockItems(state),
    pagination: selectPagination(state),
    isLoading: selectSearchIsLoading(state),
    isLoadingAdditionalResults: selectSearchIsLoadingAdditionalResults(state),
    searchLogParameters: selectSearchLogParameters(state),
    username: selectUsername(state),
    shouldShowAddToFavorites: features.shouldShowAddToFavorites,
    favoritesBinId: features.favoritesBinId,
    context: context,
    drawerIsOpen: selectDrawerIsOpen(state),
    drawerSelectedItemId: selectDrawerSelectedItemId(state),
    statusCode: selectSearchStatusCode(state),
    countryCode: selectSearchCountryCode(state),
    hideSearchResults: selectHideSearchResults(state),
    useSubscriptionlessSignUpEnticement:
      get(features, 'useSubscriptionlessSignUpEnticement') ||
      get(detailsFeatures, 'useSubscriptionlessSignUpEnticement') ||
      get(memberBinFeatures, 'useSubscriptionlessSignUpEnticement'),
    shouldUseRemainingSearchUi: features.useRemainingSearchUI,
  };
}

export default AbstractSearchResultsContainer;
